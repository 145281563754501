body {
    background-color: #f5f5f5;
    margin: 0;
    padding-top: 2em;
    padding-bottom: 2em;
}

h1, h2, h3, a, p, input {
    font-family: Andale Mono, monospace;
}

h1, h2, h3, input {
    color: #424242;
}

p {
    line-height: 1.4;
    color: #424242;
}

a:hover {
    opacity: .5;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    opacity: .5;
}

input {
    border-width: 0;
    border-bottom-width: 1px;
    background-color: #00000000;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.loader {
    border: 2px solid #42424222;
    border-top: 2px solid #888888;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
